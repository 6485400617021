import getConfig from 'next/config';

type configOptions = {
  [key: string]: string;
};
const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();
const Config: configOptions = {
  ENV: publicRuntimeConfig.appEnv || 'development',
  APP_URL: publicRuntimeConfig.myAppUrl || 'http://my.solesavy.local',
  WORDPRESS_URL: publicRuntimeConfig.wordpressUrl || 'http://www.solesavy.local',
  SENTRY_DSN: 'https://ee976c75e0d14f5aa24fe5b294fd1ede@o918384.ingest.sentry.io/5979025',
  RELEASES_URL: process.env.releasesUrl || '/releases',
  EDUCATION_CENTER:
    process.env.NEXT_PUBLIC_EDUCATION_CENTER ||
    'https://help.solesavy.com/en/articles/5354579-pre-cart-method-how-to-beat-the-bots',
  GET_HELP: '/help',
  MEMBER_FAQ: 'https://help.solesavy.com/en/articles/5305383-solesavy-faq-s',
  GENERAL_FAQ: 'https://solesavy.com/faq',
  STORE: process.env.NEXT_PUBLIC_STORE || 'https://store.solesavy.com',
  SLACK_URL: 'https://slack.com',
  LAUNCH_SLACK_URL: 'https://solesavy.enterprise.slack.com/',
  LAUNCH_ASSIST_URL:
    'https://chrome.google.com/webstore/detail/assist-by-solesavy/fbglcckkpnofkibjakkfbmkhfmkcappm?hl=en/',
  OPEN_SLACK_DEEP_LINK: 'slack://open',
  SLACK_ANDROID_APP: 'https://play.google.com/store/apps/details?id=com.Slack',
  SLACK_APPLE_APP: 'https://itunes.apple.com/app/slack-app/id618783545?ls=1&mt=8',
  OPEN_COLLECT_DEEP_LINK: process.env.OPEN_COLLECT_DEEP_LINK || 'com.solesavy://',
  COLLECT_ANDROID_APP: 'https://play.google.com/store/apps/details?id=com.solesavy&hl=en_US&gl=US',
  COLLECT_APPLE_APP: 'https://apps.apple.com/app/collect/id1588228838',
  LOOM_URL: 'https://www.loom.com',
  OPEN_DROPS_DEEP_LINK: 'solesavy://',
  DROPS_ANDROID_APP: 'https://play.google.com/store/apps/details?id=com.solesavyts',
  DROPS_APPLE_APP: 'https://apps.apple.com/ca/app/solesavy/id1494807663',
  TAG_MANAGER_CONTAINER_ID: process.env.NEXT_PUBLIC_TAG_MANAGER_CONTAINER_ID || 'GTM-KBZH495',
  SEGMENT_API_KEY: process.env.NEXT_PUBLIC_SEGMENT_API_KEY || 'MS7Jl0mHBd0YBtuvfdH5BhzNI9y6FGCY',
  SEGMENT_TO_TRACK: process.env.NEXT_PUBLIC_SEGMENT_TO_TRACK || '',
  SUPPORT_EMAIL: 'support@solesavy.com',
  INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID || 'isxc47cc',
  INTERCOM_API_TOKEN: publicRuntimeConfig.intercomToken || '',
  INTERCOM_URL: 'https://api.intercom.io',
  CALENDLY_TRAINING_URL:
    publicRuntimeConfig.calendlyUrl ||
    'https://calendly.com/solesavy-staging-onboarding/solesavy-onboarding-1-1-staging',
  SANITY_PROJECT_ID: publicRuntimeConfig.sanityProjectId || 'g0fsbry0',
  SANITY_DATA_SET: publicRuntimeConfig.sanityDataSet || 'production',
  SANITY_API_VERSION: publicRuntimeConfig.sanityApiVersion || '2021-05-03',
  SANITY_API_TOKEN: publicRuntimeConfig.sanityToken || '',
  FEATUREHUB_URL: 'https://toggles.solesavy.ca',
  FEATUREHUB_API_KEY: publicRuntimeConfig.featurehubToken || '',
  OAUTH_REDIRECT_URL: publicRuntimeConfig.oauthRedirectUrl || '/api/v1/auth/oauth-redirect/',
  SSO_URL: publicRuntimeConfig.ssoUrl || 'http://accounts.solesavy.local',
  FA_CLIENT_ID: publicRuntimeConfig.faClientId || '',
  FA_API_KEY: serverRuntimeConfig.faApiKey || '',
  CHARGEBEE_SITE: publicRuntimeConfig.chargebeeSite || 'solesavy-test',
  CHARGEBEE_API_KEY: serverRuntimeConfig.chargebeeApiKey || '',
  ACTIVE_CAMPAIGN_API_URL: publicRuntimeConfig.activeCampaignUrl || '',
  ACTIVE_CAMPAIGN_FIELD_MAPPING: publicRuntimeConfig.activeCampaignFieldMapping || '',
  ACTIVE_CAMPAIGN_LIST: publicRuntimeConfig.activeCampaignList || '',
  ACTIVE_CAMPAIGN_API_KEY: serverRuntimeConfig.activeCampaignKey || '',
};

export const nonStringConfig = {
  ALLOWED_MEMBERSHIPS: (process.env.ALLOWED_MEMBERSHIPS || 'premium').split(','),
};
export default Config;
